import ReactMarkdown from 'react-markdown' //https://bestofjs.org/projects/react-markdown
import "./memo.css"

function Memo(props) {
    return (
        <div className='memo'>
            <div className='memodetails'>
                <p>{props.memodate}<br/>{props.memotitle}</p>
            </div>
            <div className='memocontents'>
                <ReactMarkdown children={props.contents}/>
            </div>
        </div>
    )
}

export default Memo