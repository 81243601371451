import Container from 'react-bootstrap/Container' //https://react-bootstrap.github.io/getting-started/introduction/
//https://stackoverflow.com/questions/42928530/how-do-i-load-a-markdown-file-into-a-react-component
//https://stackoverflow.com/questions/44506207/reactjs-lifecycle-method-inside-a-function-component
//https://stackoverflow.com/questions/10049557/reading-all-files-in-a-directory-store-them-in-objects-and-send-the-object
//https://www.youtube.com/watch?v=gT1v33oA1gI

import './App.css';
import Memo from './components/memo/Memo'

import postList from './posts.json'
postList.reverse();

function App() {
  return (
    <div className="App">
      <Container className="container">
        {
          postList.map(post => (
            <Memo
              key={post.metadata.memodate+post.metadata.memotitle}
              memotitle={post.metadata.memotitle}
              memodate={post.metadata.memodate}
              contents={post.contents}
            />
          ))
        }
      </Container>
    </div>
  );
}

export default App;
